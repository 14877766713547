import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import Hero from 'components/hero';
import Cta from 'components/cta';
import Features from 'components/features';

const Mobile = ({ data }) => (
  <Layout>
    <Head pageTitle={data.mobileJson.hero.title} />
    <Hero hero={data.mobileJson.hero} />
    <Cta cta={data.mobileJson.cta} />
    <Features feature={data.mobileJson.feature} />
    <Cta cta={data.mobileJson.calloutOne} theme="light" />
    <Cta cta={data.mobileJson.calloutTwo} theme="light" />
    <Cta cta={data.mobileJson.calloutThree} theme="light" />
    <Cta cta={data.mobileJson.cta2} />
  </Layout>
);

Mobile.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Mobile;

export const query = graphql`
  query MobileQuery {
    mobileJson {
      hero {
        title
        content {
          childMarkdownRemark {
            html
          }
        }
        image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      feature {
        title
        features {
          icon
          headline
          content
          action
        }
      }
      cta {
        title
        content
        action
      }
      cta2 {
        title
        content
        action
      }
      calloutOne {
        title
        content
        image {
          childImageSharp {
            fixed(quality: 100, width: 300) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
      calloutTwo {
        title
        content
        image {
          childImageSharp {
            fixed(quality: 100, width: 300) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
      calloutThree {
        title
        content
        image {
          childImageSharp {
            fixed(quality: 100, width: 300) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  }
`;
